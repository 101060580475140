<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card to="/admin/manage_users" height="200px">
          <v-card-title class="headline">Manage Users</v-card-title>
          <v-card-text class="subtitle-1 font-regular"
            >Approve and manage user. View permits and other producer
            user.</v-card-text
          >
          <v-card-actions class="card-actions">
            <v-avatar color="indigo">
              <v-icon color="white">restaurant_menu</v-icon>
            </v-avatar>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card to="/coupons/list" height="200px">
          <v-card-title class="headline">Promotion</v-card-title>
          <v-card-text class="subtitle-1 font-regular"
            >Manage TakeIn promotions.</v-card-text
          >
          <v-card-actions class="card-actions">
            <v-avatar color="green">
              <v-icon dark>fas fa-hand-holding-usd</v-icon>
            </v-avatar>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card to="/order/list/v2.0" height="200px">
          <v-card-title class="headline">View Orders</v-card-title>
          <v-card-text class="subtitle-1 font-regular"
            >View and adjust global environment values.</v-card-text
          >
          <v-card-actions class="card-actions">
            <v-avatar color="accent">
              <v-icon dark>shopping_cart</v-icon>
            </v-avatar>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card to="/admin/settings" height="200px">
          <v-card-title class="headline">System Categories</v-card-title>
          <v-card-text class="subtitle-1 font-regular"
            >Food types, deits, etc.</v-card-text
          >
          <v-card-actions class="card-actions">
            <v-avatar color="cyan">
              <v-icon dark>settings</v-icon>
            </v-avatar>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card to="/admin/update/list" height="200px">
          <v-card-title class="headline"
            >Updates and Announcements</v-card-title
          >
          <v-card-text class="subtitle-1 font-regular"
            >Organize system update and Announcements.</v-card-text
          >
          <v-card-actions class="card-actions">
            <v-avatar color="orange">
              <v-icon light>notifications</v-icon>
            </v-avatar>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card to="/admin/support" height="200px">
          <v-card-title class="headline">Support</v-card-title>
          <v-card-text class="subtitle-1 font-regular"
            >Support and Messaging.</v-card-text
          >
          <v-card-actions class="card-actions">
            <v-avatar color="yellow">
              <v-icon>message</v-icon>
            </v-avatar>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" class="mb-4">
      <v-col cols="4"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {}
}
</script>
<style scoped>
.card-actions {
  position: absolute;
  bottom: 4%;
  right: 4%;
}
</style>
